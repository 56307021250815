html, body, #root, .App {
    height: 100%;
    margin: 0;
    padding: 0;
}
  
.App {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}